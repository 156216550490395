/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * See: https://www.gatsbyjs.org/docs/add-offline-support-with-a-service-worker/
 */

require('leaflet/dist/leaflet.css')
require('react-leaflet-markercluster/dist/styles.min.css')
require('bootstrap/dist/css/bootstrap.min.css')
require('leaflet.locatecontrol/dist/L.Control.Locate.min.css')
require('font-awesome/css/font-awesome.min.css')
